interface FixUrls {
    General: string;
    Troubleshoot: string;
    Kaba: string;
    Lynx: string;
    PointCentral: string;
    RemoteLock: string;
}

interface Tags {
    unknown: string;
    'max-codes': string;
    'wrong-id': string;
    deprecated: string;
}

class Troubleshoot {
    private contactUs: string = `For further questions you can write us to
                        <b>smarthome@vacasa.com</b>`;

    public fixUrls: FixUrls = {
        General: 'https://atlas.vacasa.com/59415-smart-lock-program',
        Troubleshoot:
            'https://atlas.vacasa.com/59415-smart-lock-program/onboarding-smart-lock-hardware-in-admin',
        Kaba: 'https://atlas.vacasa.com/62349-kaba/kaba-troubleshooting-guide',
        Lynx: 'https://atlas.vacasa.com/62348-lynx',
        PointCentral: '',
        RemoteLock: '',
    };

    private tags: Tags = {
        'max-codes': `We cannot generate more codes for this device, a common reason
                        is to have already created more than 4 passcodes for this stay.
                        There's no current solution for these, but if it keeps happening
                        then probably then device needs a reset.`,
        unknown: `The device is failing, but the reason cannot be diagnosed.
                        Please confirm this device is still been used.`,
        'wrong-id': `The device <code>externalID</code> associated is <b>wrong</b>,
                        misspellings are the most commont mistake. You should
                        go to the <code>Vendor</code> site, validate the <code>ExternalID</code>
                        and update it from Admin.`,
        deprecated: `This device is not working properly, probably removed at
                        some point but not deactivated. Please validate that it's
                        been used otherwise deactivate.`,
    };

    getTagValue = (tag: string) => this.tags[tag] + this.contactUs;
}

export default Troubleshoot;
