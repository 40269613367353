import { ADMINS } from 'core/constants';
import jwtdecode from 'jwt-decode';

interface AdminUser {
    id: string;
    email: string;
}

class Cuser {
    /**
     * Get current user's email
     */
    getEmail() {
        const idToken = localStorage.getItem('idToken');
        const decodedUser = jwtdecode(idToken);
        return decodedUser['email'];
    }

    /**
     * Get current user id based on Admin list
     */
    getId(): string {
        const admin = this.findInAdmin();
        return admin.id;
    }

    /**
     * Find user in ADMIN env provided
     */
    findInAdmin(): AdminUser | null {
        const user = ADMINS.find(([email, _id]) => email === this.getEmail());
        if (!user) return null;
        return { id: user[1], email: user[0] };
    }

    /**
     * Check if current user is admin
     */
    isAdmin(): boolean {
        const admin = this.findInAdmin();
        return !!admin;
    }

    /**
     * Check if Cuser is autenthicated
     */
    isAuthenticated = (): boolean => !!this.getEmail();
}

export default new Cuser();
