/**
 * Validates an env variable is present
 * @param variable environment var name
 */
function validateAndGetEnvVar(variable: any): string {
    const value = process.env[variable];
    if (value) return value;
    const message = `Variable ${variable} is undefined or empty`;
    throw new Error(message);
}

// Envs
export const UNIT_URL = 'https://admin.vacasa.com/admin/dashboard/units/location?UnitID=';
export const UNIT_TICKET_URL = 'https://admin.vacasa.com/admin/dashboard/tickets/units?UnitID=';
export const UNIT_TICKET_ADD_URL =
    'https://admin.vacasa.com/admin/dashboard/tickets/edit?Maintenance=1&UnitID=';
export const IDP_SCOPES = 'units:read units:write';
export const IDP_GATEWAY = process.env['REACT_APP_IDP_GATEWAY']; // empty on stage/prod
export const IDP_AUDIENCE = validateAndGetEnvVar('REACT_APP_SMARTHOME_IDP_AUDIENCE');
export const SMARTHOME_API = validateAndGetEnvVar('REACT_APP_SMARTHOME_API');
export const APP_URL = validateAndGetEnvVar('REACT_APP_APP_URL');
export const IDP_REDIRECT_URI = validateAndGetEnvVar('REACT_APP_IDP_REDIRECT_URI');
export const IDP_CLIENT_ID = validateAndGetEnvVar('REACT_APP_IDP_CLIENT_ID');
export const PUBLIC_KEY_FROM_ADMIN = '';

// Admin List csv string of email:id
export const ADMINS = validateAndGetEnvVar('REACT_APP_ADMINS')
    .split(',')
    .map((emailAndId) => emailAndId.split(':'));

// set env name
let env: 'local' | 'stage' | 'prod';
switch (APP_URL) {
    case 'http://local.smart-home-api.vacasa.io/':
        env = 'local';
        break;

    case 'https://smh-admin.smart-home.vacasastage.services':
        env = 'stage';
        break;

    case 'https://smh-admin.smart-home.vacasa.services':
        env = 'prod';
        break;

    default:
        env = 'local';
        break;
}

export const ENVIRONMENT = env;
