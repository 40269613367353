import React, { useEffect, useState } from 'react';
import { UNIT_TICKET_URL, UNIT_TICKET_ADD_URL } from 'core/constants';
import { BsChevronDown } from 'react-icons/bs';
import { getUnitUrl } from 'utils/helpers';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import TagComponent from 'components/UI/Tag';
import ToggleButton from 'react-toggle-button';
import Troubleshoot from 'services/troubleshoot.service';
import SmarthomeService from 'services/smarthome.service';
import modulecss from 'components/Gateway/Gateway.module.scss';
import homecss from 'components/Home/HomeGateways/HomeGateways.module.scss';
import c from 'classnames';

const css = { ...modulecss, ...homecss }; // merge css

const GatewayComponent = (props: any) => {
    // services
    const trouble = new Troubleshoot();
    const smarthome = new SmarthomeService();

    // hooks
    const gateway = props.gateway;
    const [active, setActive] = useState(gateway.active);
    const [open, setOpen] = useState(false);
    const [fixLoading, setFixLoading] = useState(false);
    const [connectivity, setConnectivity] = useState({ message: '', error: false });
    const [connectivityLoading, setConnectivityLoading] = useState(false);
    const [failing, setFailing] = useState(props.gateway.failing);

    const name = props.getVendorName(gateway.vendor);
    const account = props.getAccountName(gateway.vendor);

    const getUnitTicketUrl = (id: string): string => UNIT_TICKET_URL + id;
    const getUnitTicketAddUrl = (id: string): string => UNIT_TICKET_ADD_URL + id;
    const handleChangeOpen = (): void => setOpen(!open);

    /**
     * Toggles active state
     */
    const handleChangeActive = async () => {
        const id = gateway.id;
        await smarthome.patchGateways(id, { active: +!active });
        setActive(+!active);
    };

    /**
     * Checks connectivity of device
     */
    const handleConnectivityClick = async () => {
        setConnectivityLoading(true);
        const response = await smarthome.postDeviceStatus(gateway.externalId, gateway.vendor);
        setConnectivity({
            error: response.type === 'failure',
            message: response.message,
        });
        setConnectivityLoading(false);
    };

    // batch activate / deactivate
    // TODO: this can be dangerous if rerender loop,
    // override parent/child relation with redux actions
    useEffect(() => {
        if ([0, 1].includes(props.activateToggleTrigger)) {
            smarthome
                .patchGateways(gateway.id, { active: props.activateToggleTrigger })
                .then(() => setActive(props.activateToggleTrigger));
        }
    });

    /**
     * Removes the failure status
     * TODO: fix odd behavior on gateway.faling state
     */
    const handleFixClick = async () => {
        setFixLoading(true);
        await smarthome.patchGateways(gateway.id, {
            failing: 0,
            failing_tag: '',
            failing_times: 0,
            failing_message: '',
        });
        setFailing({
            status: 0,
            times: 0,
            tag: '',
            message: '',
        });
        setFixLoading(false);
    };

    return (
        <div className={css.gateway}>
            {/* Vendor */}
            <div className={c(css.row, css.row_vendor)}>
                {name && <span className={css.vendor}>{name}</span>}
            </div>

            {/* Account */}
            <div className={c(css.row, css.row_account, 'truncate')}>{account}</div>

            {/* UnitID */}
            <div className={c(css.row, css.row_unit)}>
                <a href={getUnitUrl(gateway.unitId)} rel="noopener noreferrer" target="_blank">
                    {gateway.unitId}
                </a>
            </div>

            {/* ExternalID */}
            <div className={c(css.row, css.row_id, 'truncate')}>{gateway.externalId}</div>

            {/* Active */}
            <div className={c(css.row, css.row_active)}>
                <ToggleButton
                    thumbStyle={{ borderRadius: 2 }}
                    trackStyle={{ borderRadius: 2 }}
                    colors={{ active: { base: '#52ac59' } }}
                    value={active}
                    onToggle={handleChangeActive}
                />
            </div>

            {/* Health */}
            <div className={c(css.row, css.row_health)}>
                <TagComponent
                    error={!!failing.status}
                    successMessage="Ok"
                    errorMessage="Fail"
                    className="block"
                />
            </div>

            {/* Failing Tag */}
            <div className={c(css.row, css.row_fail_tag)}>
                <div className={css.tag}>{failing.tag}</div>
            </div>

            {/* Failing Message */}
            <div className={c(css.row, css.row_fail_msg)}>
                {failing.message && <div className={css.message}>{failing.message}</div>}
            </div>

            {/* Action Trigger */}
            <div className={c(css.row, css.row_action)}>
                <BsChevronDown
                    className={c('action', open ? 'open' : '')}
                    onClick={handleChangeOpen}
                />
            </div>

            {/* Actions */}
            <div className={c(css.action_content, open ? css.open : '')}>
                <div className={css.action_content_inner}>
                    {/* Troubleshoot Help - !! = booleanParse */}
                    {!!failing.status && (
                        <div style={{ display: 'flex', margin: '0 0 10px 0' }}>
                            <div className={css.action_content_inner_left}>
                                <span className={css.action_tag}>FIX</span>
                            </div>
                            <div style={{ fontSize: '12px' }}>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: trouble.getTagValue(gateway.failing.tag),
                                    }}
                                />
                                <br />
                                Useful Links for <code>{props.name}</code> errors:
                                <br />
                                <div style={{ marginTop: '5px' }}>
                                    <a
                                        className={css.action_link}
                                        href={trouble.fixUrls.General}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Smartlock Docs
                                    </a>
                                    <a
                                        className={css.action_link}
                                        href={trouble.fixUrls.Troubleshoot}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Troubleshoot
                                    </a>
                                    {trouble.fixUrls[props.name] && (
                                        <a
                                            className={css.action_link}
                                            href={trouble.fixUrls[props.name]}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            {props.name} Docs
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Extra Tools */}
                    <div style={{ display: 'flex' }}>
                        <div className={failing.status ? css.action_content_inner_left : ''}>
                            <span className={css.action_tag}>Actions</span>
                        </div>
                        <div>
                            {!!failing.status && (
                                <button onClick={handleFixClick} className={css.action_link_bottom}>
                                    Mark as Fixed
                                    {fixLoading && (
                                        <AiOutlineLoading3Quarters
                                            className={c(css.action_spinner, 'spinner')}
                                        />
                                    )}
                                </button>
                            )}
                            <a
                                className={css.action_link_bottom}
                                href={getUnitTicketAddUrl(gateway.id)}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Create Ticket
                            </a>
                            <a
                                className={css.action_link_bottom}
                                href={getUnitTicketUrl(gateway.id)}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                View Tickets
                            </a>
                            <button
                                onClick={handleConnectivityClick}
                                className={css.action_link_bottom}
                            >
                                Check Connectivity
                                {connectivityLoading && (
                                    <AiOutlineLoading3Quarters
                                        className={c(css.action_spinner, 'spinner')}
                                    />
                                )}
                            </button>
                            {connectivity.message && (
                                <span
                                    className={c(
                                        css.action_message,
                                        connectivity.error ? css.error : css.success,
                                    )}
                                >
                                    {connectivity.message}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GatewayComponent;
