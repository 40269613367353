import React from 'react';

interface ptype {
    placeholder: string;
    className?: string;
    value?: string;
    onChange?: any;
}

const InputComponent = (props: ptype) => {
    const className = props.className || null;
    const onChange = props.onChange || null;
    return (
        <input
            className={className}
            value={props.value}
            placeholder={props.placeholder}
            onChange={onChange}
        />
    );
};

export default InputComponent;
