import axios from 'axios';
import { SMARTHOME_API } from 'core/constants';
import AuthService from 'services/auth.service';
import { isBoolean } from 'lodash';
import cuser from 'services/cuser.service';
import {
    Filter,
    Vendor,
    Device,
    Headers,
    Gateway,
    Passcode,
    Pagination,
} from 'services/smarthome.service.d';

class SmarthomeService {
    public source;
    public pagination: Pagination = {
        size: 30,
        pages: 0,
    };

    private baseUrl: string = SMARTHOME_API;

    /**
     * Build headers with Bearer token
     */
    getHeaders(): Headers {
        const token = AuthService.idpToken;
        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
    }

    /**
     * Get Total pages for pagination
     */
    getTotalPages(): number {
        return this.pagination.pages;
    }

    /**
     * Get list of device gateways, we skip {params} on axios
     * to avoid
     */
    getGateways(filter: Filter = {}, setPage: boolean = true): Promise<Gateway[]> {
        this.source = axios.CancelToken.source();
        let url = `${this.baseUrl}/device_gateways`;
        const headers = this.getHeaders(),
            filter_active = ['0', '1'].includes(filter.active),
            filter_failing = ['0', '1'].includes(filter.failing);

        // size && skip deleted
        const query = [`filter[deleted_at.isnull]=true`, `page[size]=${this.pagination.size}`];

        if (filter_active) query.push(`filter[active]=${filter.active}`);
        if (filter?.vendor?.length) query.push(`filter[vendor.in]=${filter.vendor.join(',')}`);
        if (filter_failing) query.push(`filter[failing]=${filter.failing}`);
        if (filter.tag) query.push(`filter[failing_tag]=${filter.tag}`);
        if (filter.unitId) query.push(`filter[property_id.icontains]=${filter.unitId}`);
        if (filter.externalId) query.push(`filter[external_id.icontains]=${filter.externalId}`);
        if (filter.message) query.push(`filter[failing_message.icontains]=${filter.message}`);
        if (filter?.ids?.length) query.push(`filter[device_gateway_id.in]=${filter.ids.join(',')}`);
        if (filter.page) query.push(`page[number]=${filter.page}`);

        url += '?' + query.join('&');
        return axios
            .get(url, { headers, cancelToken: this.source.token })
            .then((res) => {
                // set pages and chain
                if (setPage) this.pagination.pages = res.data.meta.num_pages;
                return res.data.data;
            })
            .then(this.serializeGateways)
            .catch((_err) => []);
    }

    /**
     * Updates a gateway
     * @param id gateway id
     * @param attributes data to be updated
     */
    patchGateways(id: string, attributes: object): Promise<Gateway[]> {
        const headers = this.getHeaders();
        const url = `${this.baseUrl}/device_gateways/${id}`;
        const body = {
            data: {
                type: 'device_gateways',
                attributes,
            },
        };
        return axios.patch(url, body, { headers });
    }

    /**
     * Get devices from gateway
     * @param gatewayId
     */
    getDevices(gatewayId: string, params): Promise<Device[]> {
        const url = `${this.baseUrl}/devices?filter[device_gateway]=${gatewayId}`;
        const headers = this.getHeaders();
        return axios
            .get(url, { headers, params })
            .then((res) => res.data.data)
            .then(this.serializeDevices);
    }

    /**
     * Create a status request to the vendor
     * @param id gateway id
     */
    postDeviceStatus(id: string, vendorId: string): Promise<any> {
        const headers = this.getHeaders();
        const url = `${this.baseUrl}/device_status`;
        const data = {
            type: 'device_status',
            attributes: {
                device_id: `${id}`,
                vendor_id: vendorId,
            },
        };
        return axios.post(url, { data }, { headers }).then((res) => res.data.data[0]);
    }

    /**
     * Get list of Vendors
     */
    getVendors(id = 0): Promise<Vendor[]> {
        let url: string;
        const headers = this.getHeaders();

        if (id) {
            // single query
            url = `${this.baseUrl}/vendors/${id}`;
            return axios
                .get(url, { headers })
                .then((res) => [res.data.data])
                .then(this.serializeVendors);
        }

        // muti query
        url = `${this.baseUrl}/vendors?sort=name&filter[active]=1`;
        return axios
            .get(url, { headers })
            .then((res) => res.data.data)
            .then(this.serializeVendors);
    }

    /**
     * Get Passcodes
     * @param id passcode id
     */
    getPasscodes(filter = {} as any): Promise<Passcode[]> {
        let url = `${this.baseUrl}/passcodes`;
        const headers = this.getHeaders();

        // single passcode query
        if (filter.singleId) {
            url += `/${filter.singleId}`;
            headers['Operator-ID'] = cuser.getId();
            return axios
                .get(url, { headers })
                .then((res) => [res.data.data])
                .then(this.serializePasscodes);
        }

        // multiple passcodes query
        const query = ['sort=-created_at', `page[size]=${this.pagination.size}`];

        // build query
        const filter_deleted = isBoolean(filter.deleted) ? filter.deleted.toString() : null;
        if (filter_deleted) query.push(`filter[deleted_at.isnull]=${filter_deleted}`);
        if (filter.id) query.push(`filter[passcode_id.icontains]=${filter.id}`);
        if (filter.type) query.push(`filter[user_type]=${filter.type}`);
        if (filter.unit) query.push(`filter[device_gateway__property_id.icontains]=${filter.unit}`);
        if (filter.gateway) query.push(`filter[device_gateway]=${filter.gateway}`);
        if (filter.externalUid)
            query.push(`filter[external_user_id.icontains]=${filter.externalUid}`);
        if (filter.page) query.push(`page[number]=${filter.page}`);
        url += '?' + query.join('&');
        return axios
            .get(url, { headers })
            .then((res) => {
                this.pagination.pages = res.data.meta.num_pages;
                return res.data.data;
            })
            .then(this.serializePasscodes);
    }

    /**
     * TODO: replace by model
     * @param passcode jsonapi
     */
    serializePasscodes(passcodes = []): Passcode[] {
        return passcodes.map((passcode) => {
            const attr = passcode.attributes;
            const code = attr.access_codes;
            return {
                id: passcode.id,
                firsname: attr.first_name,
                lastname: attr.last_name,
                fullname: `${attr.first_name} ${attr.last_name}`,
                phone: attr.phone,
                code: code?.length ? code[0].access_code : '',
                codeDesc: code?.length ? code[0].property_access_description : '',
                email: attr.email,
                starttime: attr.start_time,
                endtime: attr.end_time,
                type: attr.user_type,
                transactionId: attr.transaction_id,
                updateAt: attr.update_at,
                createdAt: attr.created_at,
                deletedAt: attr.deleted_at,
                deleted: !!attr.deleted_at,
                gatewayId: attr.device_gateway,
                reservationUnitId: attr.external_user_id,
            };
        });
    }

    /**
     * TODO: replace by model
     * @param vendors raw jsonapi
     */
    serializeVendors(vendors = []): Vendor[] {
        return vendors.map((vendor) => {
            const attr = vendor.attributes;
            return {
                id: vendor.id,
                name: attr.name,
                account: attr.subaccount,
                display: attr.display_name,
            };
        });
    }

    /**
     * Retrieve serializer
     * @param devices raw jsonapi
     */
    serializeDevices(devices = []): Device[] {
        return devices.map((device) => {
            const attr = device.attributes;
            return {
                id: device.id,
                description: attr.description,
            };
        });
    }

    /**
     * Retrieve Serializer for Gateways
     * @param gateways raw jsonapi
     */
    serializeGateways(gateways = []): Gateway[] {
        return gateways.map((gateway) => {
            const attr = gateway.attributes;
            return {
                id: gateway.id,
                vendor: attr.vendor,
                account: '',
                active: attr.active,
                externalId: attr.external_id,
                unitId: attr.property_id,
                failing: {
                    status: attr.failing,
                    tag: attr.failing_tag,
                    times: attr.failing_times,
                    message: attr.failing_message,
                },
            };
        });
    }
}

export default SmarthomeService;
