import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HomeGateways from './components/Home/HomeGateways/HomeGateways';
import { ImplicitFlow } from '@vacasa/react-components-lib';
import 'react-datepicker/dist/react-datepicker.css';
import './App.scss';
import {
    IDP_REDIRECT_URI,
    IDP_CLIENT_ID,
    IDP_SCOPES,
    IDP_AUDIENCE,
    ENVIRONMENT,
} from 'core/constants';

const App = () => {
    return (
        <ImplicitFlow
            directoryHint=""
            clientId={IDP_CLIENT_ID}
            redirectUrl={IDP_REDIRECT_URI}
            scopes={IDP_SCOPES}
            audience={IDP_AUDIENCE}
            // on local authenticate on stage
            env={ENVIRONMENT === 'local' ? 'stage' : ENVIRONMENT}
            followRedirect={false}
        >
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={HomeGateways} />
                </Switch>
            </BrowserRouter>
        </ImplicitFlow>
    );
};

export default App;
