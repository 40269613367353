import { UNIT_URL } from 'core/constants';
import { format } from 'date-fns';

/**
 * Skippers for useEffect only on updates
 */
export const skipFirstRun = (isFirstRef) => {
    // skip first time for results
    if (!isFirstRef.current) return false;
    isFirstRef.current = false;
    return true;
};

/**
 * Get unit Url
 * @param id UnitID
 */
export const getUnitUrl = (id: string): string => {
    return UNIT_URL + id;
};

/**
 * Wrapper that captures failure around date-fns.format
 * @param date date-fns
 * @param dformat date-fns format
 */
export const safeFormat = (date, dformat) => {
    try {
        return format(date, dformat);
    } catch (e) {
        return '';
    }
};
