import React, { useState, useEffect, useRef, useCallback } from 'react';
import Select from 'components/Form/Select/Select';
import Input from 'components/Form/Input/Input';
import ReactPaginate from 'react-paginate';
import SmarthomeService from 'services/smarthome.service';
import cuser from 'services/cuser.service';
import { Filter } from 'services/smarthome.service.d';
import GatewayComponent from 'components/Gateway/Gateway';
import { skipFirstRun } from 'utils/helpers';
import { Placeholders } from 'components/Home/HomeGateways/HomeGateways.d';
import { useLoader, usePagination } from 'components/Home/Hooks';
import css from './HomeGateways.module.scss';
import c from 'classnames';
import _ from 'lodash';
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';
import { CSVLink } from 'react-csv';

/* eslint-disable react-hooks/exhaustive-deps */
const HomeGateways = () => {
    const smarthome = new SmarthomeService();

    const placeholders: Placeholders = {
        vendors: 'Vendors',
        accounts: 'Accounts',
        health: 'Health',
        tag: 'Tag',
        active: 'Status',
        message: 'Error Message',
    };

    // first time flags
    const isFirstRun = useRef(true),
        isFirstAccountRun = useRef(true),
        isFirstVendorRun = useRef(true),
        isFirstDebounceRun = useRef(true),
        isFirstPaginationRun = useRef(true),
        csvLinkRef = useRef();

    // state data
    const [vendors, setVendors] = useState([]),
        [gateways, setGateways] = useState([]),
        [csvData, setCSVData] = useState([]),
        [error, setError] = useState(false),
        [activateSwitchTrigger, setActivateSwitchTrigger] = useState(-1); // -1 means unset

    // state filters
    const [selectedVendor, setSelectedVendor] = useState(''),
        [selectedVendorIds, setSelectedVendorIds] = useState([]),
        [selectedExternalId, setSelectedExternalId] = useState(''),
        [selectedAccount, setSelectedAccount] = useState(''),
        [selectedMessage, setSelectedMessage] = useState(''),
        [selectedUnitId, setSelectedUnitId] = useState(''),
        [selectedHealth, setSelectedHealth] = useState('1'),
        [selectedActive, setSelectedActive] = useState(''),
        [selectedTag, setSelectedTag] = useState('');

    const [
        // pagination hook
        pagination,
        setPagination,
        clearPagination,
        setCurrentPagination,
        getPaginationState,
    ] = usePagination(smarthome);

    const [
        // loader hook
        loading,
        setLoading,
        setResultLoader,
        unsetResultLoader,
    ] = useLoader();

    const handleActivateAll = () => setActivateSwitchTrigger(1);
    const handleDeactivateAll = () => setActivateSwitchTrigger(0);
    const resetActivateSwitchTrigger = () => setActivateSwitchTrigger(-1);

    /**
     * Load Vendors and Data and set as ready
     * ComponentDidMount
     */
    useEffect(() => {
        const ComponentDidMount = async () => {
            let gateways,
                vendors = [];
            try {
                const params = getFilterParams();
                vendors = await smarthome.getVendors();
                gateways = await smarthome.getGateways(params);
            } catch (e) {
                handleError(e);
            }
            setVendors(vendors);
            setGateways(gateways);
            setPagination(getPaginationState());
            setLoading({ filters: false, results: false });
        };
        ComponentDidMount();
    }, []);

    /**
     * Update Results when any of the not debounce filters change
     */
    useEffect(() => {
        if (skipFirstRun(isFirstRun)) return;
        setResultLoader();
        resetActivateSwitchTrigger();
        updateResults(getFilterParams());
    }, [selectedActive, selectedHealth, selectedTag]);

    /**
     * Update Results when debounce filters change
     */
    useEffect(() => {
        if (skipFirstRun(isFirstDebounceRun)) return;
        setResultLoader();
        resetActivateSwitchTrigger();
        updateResultsDebounce(getFilterParams());
    }, [selectedUnitId, selectedExternalId, selectedMessage]);

    /**
     * On selected account update gateways
     */
    useEffect(() => {
        if (skipFirstRun(isFirstAccountRun)) return;
        setGateways([]); // fix problem without immutable
        const vendorID = selectedAccount;
        const isReset = vendorID === placeholders.accounts;
        const params = getFilterParams(); // set filter params
        params.vendor = isReset ? [] : [vendorID];
        // get gateways and update state
        smarthome.getGateways(params).then((gateways) => {
            unsetResultLoader();
            resetActivateSwitchTrigger();
            setPagination(getPaginationState());
            setGateways(gateways);
        });
    }, [selectedAccount]);

    /**
     * On changed current page
     */
    useEffect(() => {
        if (skipFirstRun(isFirstPaginationRun)) return;
        setGateways([]); // fix problem without immutable
        smarthome.getGateways(getFilterParams()).then((gateways) => {
            unsetResultLoader();
            resetActivateSwitchTrigger();
            setGateways(gateways);
        });
    }, [pagination.current]);

    /**
     * Update gateways on updated VendorIds, that means
     * a vendor selected
     */
    useEffect(() => {
        if (skipFirstRun(isFirstVendorRun)) return;
        setGateways([]); // fix problem without immutable
        const name = selectedVendor;
        const vendorID = selectedVendorIds;
        const isReset = name === placeholders.accounts;
        const params = isReset ? {} : { vendor: [vendorID] };
        smarthome.getGateways(params).then((gateways) => {
            unsetResultLoader();
            resetActivateSwitchTrigger();
            setPagination(getPaginationState());
            setGateways(gateways);
        });
    }, [selectedVendorIds]);

    /**
     * Create params for combined search filters
     */
    const getFilterParams = (): Filter => {
        const params = { page: pagination.current + 1 } as any;
        const filterByTag = selectedTag !== placeholders.tag;
        const filterByHealth = selectedHealth !== placeholders.health;
        const filterByActive = selectedActive !== placeholders.active;
        const filterByAccount = selectedAccount && selectedAccount !== placeholders.accounts;

        if (filterByActive) params.active = selectedActive;
        if (filterByTag) params.tag = selectedTag;
        if (filterByHealth) params.failing = selectedHealth;
        if (filterByAccount) params.vendor = [selectedAccount];
        if (selectedExternalId) params.externalId = selectedExternalId;
        if (selectedUnitId) params.unitId = selectedUnitId;
        if (selectedMessage) params.message = selectedMessage;
        if (selectedVendorIds.length) params.vendor = selectedVendorIds;

        return params;
    };

    /**
     * Handle search on filter
     */
    const handleChangeFilterMessage = (event) => {
        const message = event.target.value;
        clearPagination();
        resetActivateSwitchTrigger();
        setSelectedMessage(message);
    };

    /**
     * Health is actually the failing_status so this
     * logic might result consufing Health means Failing
     */
    const handleChangeFilterHealth = async (event) => {
        const health = event.target.value;
        clearPagination();
        resetActivateSwitchTrigger();
        setSelectedHealth(health);
    };

    /**
     * Update Filter for seelected Tag
     */
    const handleChangeFilterTag = (event) => {
        const tag = event.target.value;
        clearPagination();
        resetActivateSwitchTrigger();
        setSelectedTag(tag);
    };

    /**
     * Handle Filter for Active Gateways
     */
    const handleChangeFilterActive = async (event) => {
        const active = event.target.value;
        clearPagination();
        resetActivateSwitchTrigger();
        setSelectedActive(active);
    };

    /**
     * Update results based on selected filters
     */
    const updateResults = async (params: Filter) => {
        setGateways([]); // fix problem
        smarthome.source?.cancel(); // cancel previous request
        const gateways = await smarthome.getGateways(params);
        setGateways(gateways);
        setPagination(getPaginationState());
        unsetResultLoader();
    };

    /**
     * Debounced version of updateResults
     */
    const updateResultsDebounce = useCallback(_.debounce(updateResults, 400), []);

    /**
     * handles unitId changes from input
     */
    const handleChangeFilterUnit = (event) => {
        const unitId = event.target.value;
        clearPagination();
        resetActivateSwitchTrigger();
        setSelectedUnitId(unitId);
    };

    /**
     * handles externalId changes from input
     */
    const handleChangeFilterExternal = (event) => {
        const externalId = event.target.value;
        clearPagination();
        resetActivateSwitchTrigger();
        setSelectedExternalId(externalId);
    };

    /**
     * On Account selected
     */
    const handleChangeFilterAccount = async (event) => {
        setResultLoader();
        resetActivateSwitchTrigger();
        clearPagination();
        setSelectedAccount(event.target.value);
    };

    /**
     * On Vendor select change
     */
    const handleChangeFilterVendor = async (event) => {
        clearFilters();
        setResultLoader();
        clearPagination();
        const name = event.target.value;
        const vendorID = vendors
            .filter((vendor) => vendor.name === name)
            .map((vendor) => vendor.id);
        setSelectedVendor(name);
        setSelectedVendorIds(vendorID);
    };

    /**
     * Handles Pagination Click
     */
    const handlePageClick = async (data) => {
        setResultLoader();
        setCurrentPagination(data.selected);
    };

    /**
     * Handle error, in case 403 token expired then reload
     */
    const handleError = (error) => {
        if (!error.toJSON) return console.log(error);
        const message = error.toJSON().message;
        if (message.indexOf('403') > 0) {
            localStorage.removeItem('accessToken');
            window.location.href = '/';
        }
        setError(true);
    };

    /**
     * Clears search filters
     */
    const clearFilters = () => {
        setSelectedAccount('');
        setSelectedExternalId('');
        setSelectedMessage('');
        setSelectedUnitId('');
        setSelectedHealth('');
        setSelectedActive('');
        setSelectedTag('');
    };

    /**
     * Get Vendor name
     * @param id vendor id
     */
    const getVendorName = (id: string): string => {
        const vendor = vendors.find((v) => v.id === id);
        return vendor ? vendor.name : '';
    };

    /**
     * Get Vendor account
     * @param id vendor id
     */
    const getAccountName = (id: string): string => {
        const vendor = vendors.find((v) => v.id === id);
        return vendor ? vendor.account : '';
    };

    /**
     * Filter accounts by vendor name
     */
    const filterByVendor = (vendor) => {
        const selectedName = selectedVendor;
        if (!selectedName || selectedName === placeholders.vendors) return true;
        return vendor.name === selectedVendor; // filter by Name
    };

    // render or early return for rerenders
    if (error) return <div className="loader">Error loading data...</div>;
    if (loading.filters) return <div className="loader">Loading smarthome data...</div>;

    const options = {
        vendors: Object.keys(_.groupBy(vendors, (v) => v.name)),
        accounts: vendors
            .map((v) => Object({ id: v.id, value: v.display, name: v.name }))
            .filter(filterByVendor),
    };

    // result set downloader
    const downloader = async (): Promise<any[]> => {
        const downloadedData: any[] = [
            [
                'Property ID',
                'External ID',
                'Vendor',
                'Account',
                'Status',
                'Health',
                'Error Message',
            ],
        ];
        const filters = getFilterParams();
        for (let i = 0; i < pagination.pages; i++) {
            filters.page = i + 1;
            await smarthome.getGateways(filters).then((gateways) => {
                for (const gateway of gateways) {
                    const row = [
                        gateway.unitId,
                        gateway.externalId,
                        getVendorName(gateway.vendor),
                        getAccountName(gateway.vendor),
                        gateway.active,
                        gateway.failing.status,
                        gateway.failing.message,
                    ];
                    downloadedData.push(row);
                }
            });
        }
        return downloadedData;
    };

    return (
        <div className={css.container}>
            <div className={css.header}>
                <Select
                    className={css.row_vendor}
                    placeholder={placeholders.vendors}
                    options={options.vendors}
                    value={selectedVendor}
                    onChange={handleChangeFilterVendor}
                />

                <Select
                    className={css.row_account}
                    placeholder={placeholders.accounts}
                    options={options.accounts}
                    value={selectedAccount}
                    onChange={handleChangeFilterAccount}
                />

                <Input
                    className={css.row_unit}
                    placeholder="UnitID"
                    value={selectedUnitId}
                    onChange={handleChangeFilterUnit}
                />

                <Input
                    className={css.row_id}
                    placeholder="ExternalID"
                    value={selectedExternalId}
                    onChange={handleChangeFilterExternal}
                />

                <Select
                    className={css.row_active}
                    placeholder={placeholders.active}
                    value={selectedActive}
                    options={[
                        { id: 1, value: 'Active' },
                        { id: 0, value: 'Inactive' },
                    ]}
                    onChange={handleChangeFilterActive}
                />

                <Select
                    className={css.row_health}
                    placeholder={placeholders.health}
                    value={selectedHealth}
                    options={[
                        { id: 0, value: 'Ok' },
                        { id: 1, value: 'Fail' },
                    ]}
                    onChange={handleChangeFilterHealth}
                />

                <Select
                    className={css.row_fail_tag}
                    placeholder={placeholders.tag}
                    value={selectedTag}
                    options={['wrong-id', 'max-codes', 'deprecated', 'unknown']}
                    onChange={handleChangeFilterTag}
                />

                <Input
                    className={css.row_message}
                    placeholder={placeholders.message}
                    value={selectedMessage}
                    onChange={handleChangeFilterMessage}
                />

                <Button
                    onClick={async (_event) => {
                        try {
                            const data = await downloader();
                            setCSVData(data);
                            const inputElement = csvLinkRef.current as unknown as CSVLink;
                            inputElement.link.click();
                        } catch (e) {
                            handleError(e);
                        }
                    }}
                >
                    {' '}
                    <DownloadIcon />
                </Button>
                <CSVLink data={csvData} filename="Gateway Results.csv" ref={csvLinkRef} />
            </div>

            <div className={css.body}>
                {loading.results && <div className="loader-results">Loading results...</div>}

                {!loading.results && !gateways.length && (
                    <div className="not-found">No result found</div>
                )}

                {!loading.results &&
                    gateways.map((gateway, idx) => {
                        return (
                            <GatewayComponent
                                key={idx}
                                refresh={updateResults}
                                activateToggleTrigger={activateSwitchTrigger}
                                getVendorName={getVendorName}
                                getAccountName={getAccountName}
                                gateway={gateway}
                            />
                        );
                    })}
            </div>

            <ReactPaginate
                previousLabel={'◄'}
                nextLabel={'►'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                forcePage={pagination.current}
                pageCount={pagination.pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                // subContainerClassName={'pages pagination'}
                activeClassName={'active'}
            />

            {cuser.isAdmin() && (
                <div style={{ margin: '10px' }}>
                    <button className={c(css.action, css.success)} onClick={handleActivateAll}>
                        Activate Page
                    </button>

                    <button className={c(css.action, css.error)} onClick={handleDeactivateAll}>
                        Deactivate Page
                    </button>
                </div>
            )}
        </div>
    );
};

export default HomeGateways;
