import React from 'react';

interface ptype {
    placeholder: string;
    value?: string | number;
    disabled?: boolean;
    className?: string;
    options?: any[];
    onChange?: any;
}

const SelectComponent = (props: ptype) => {
    const options = props.options || [];
    const className = props.className || null;
    const value = props.value || '';
    const onChange = props.onChange || undefined;
    return (
        <select disabled={props.disabled} className={className} onChange={onChange} value={value}>
            <option>{props.placeholder}</option>
            {options.map((option, idx) => {
                if (typeof option == 'string')
                    return (
                        <option value={option} key={idx}>
                            {option}
                        </option>
                    );
                return (
                    <option value={option.id} key={idx}>
                        {option.value}
                    </option>
                );
            })}
        </select>
    );
};

export default SelectComponent;
