import React from 'react';
import css from './Tag.module.scss';
import c from 'classnames';

export interface Props {
    error: boolean;
    errorMessage: string;
    successMessage: string;
    className?: string;
}

const TagComponent = (props: Props) => {
    const extraClassName = props.className ?? '';

    if (props.error)
        // error render
        return (
            <div className={c(css.status, css.status_fail, extraClassName)}>
                {props.errorMessage}
            </div>
        );

    // success
    return (
        <div className={c(css.status, css.status_success, extraClassName)}>
            {props.successMessage}
        </div>
    );
};

export default TagComponent;
