import { useState } from 'react';

/**
 * Loader Hooks
 */
export const useLoader = () => {
    const [loading, setLoading] = useState({
        filters: true,
        results: true,
    });
    const setResultLoader = () => setLoading({ filters: false, results: true });
    const unsetResultLoader = () => setLoading({ filters: false, results: false });
    return [loading, setLoading, setResultLoader, unsetResultLoader] as const;
};

/**
 * Pagination Hooks
 * @param smarthome Smarthome Service instance
 */
export const usePagination = (smarthome) => {
    const [pagination, setPagination] = useState({
        current: 0,
        pages: 0,
    });

    /**
     * Set current page to 0
     */
    const clearPagination = () => setCurrentPagination(0);

    /**
     * Set current page in state
     * @param current current page
     */
    const setCurrentPagination = (current: number) =>
        setPagination({
            ...pagination,
            current,
        });

    /**
     * Get pagination state based on smarthome service instance
     */
    const getPaginationState = () => {
        return {
            ...pagination,
            pages: smarthome.getTotalPages(),
        };
    };

    return [
        pagination,
        setPagination,
        clearPagination,
        setCurrentPagination,
        getPaginationState,
    ] as const;
};
